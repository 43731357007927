import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

import BonusBenefits from "./icons/bonusBenefits";
import SaveMoney from "./icons/saveMoney";
import SaveTime from "./icons/saveTime";
import FeatureList from "@components/shared/featureList";

const benefitsList = [
    {
        title: "Save Money",
        description:
            "Home warranties save homeowners time by streamlining repairs and replacements.  With a warranty, homeowners can easily submit a claim and have a technician dispatched promptly, eliminating the need for time-consuming searches and scheduling.",
        icon: <SaveMoney />,
    },
    {
        title: "Save Time",
        description:
            "Home warranties can also save homeowners time by simplifying the process of getting repairs or replacements. With a home warranty, homeowners can submit a claim to have a technician dispatched automatically, usually within a day or two. This means homeowners don't have to spend time searching for a qualified technician or scheduling appointments themselves.",
        icon: <SaveTime />,
    },
    {
        title: "Bonus Benefits",
        description:
            "Some home warranty companies offer additional benefits to sweeten the deal. Homeowners may be eligible for exclusive discounts on home security products, restaurants, or popular products. However, these benefits vary based on the home warranty company and chosen plan.",
        icon: <BonusBenefits />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <FeatureList
            featureList={benefitsList}
            classes={{
                innerSectionClasses:
                    "lg:flex flex-wrap justify-between  lg:mb-[150px] mt-[120px]",
                oneBoxClasses: `text-center mb-12  w-full lg:w-[32%] py-16 ${styles.item}`,
                iconClasses: "children:mx-auto",
                titleClasses: "font-bold text-xl lg:text-[25px] mt-[42px] ",
                descriptionClasses:
                    "text-base  leading-[28px] px-5 mx-auto mt-3",
            }}
        />
    );
}
