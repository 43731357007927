import React from "react";

function SaveMoney() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="180"
            height="187"
            fill="none"
            viewBox="0 0 180 187"
        >
            <path
                fill="#93C1DB"
                d="M58.925 57.87s-3.919-24.692 26.289-25.574c.002 0-1.33 21.88-26.29 25.573z"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M82.381 34.742a.228.228 0 01-.012.322L63.476 52.617a.228.228 0 01-.31-.335L82.059 34.73a.228.228 0 01.322.012z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M80.366 40.552a.228.228 0 01-.174.272l-5.021 1.098a.228.228 0 11-.098-.445l5.022-1.099a.228.228 0 01.271.174z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M76.537 36.433c.12.036.19.163.154.283l-1.35 4.593a.228.228 0 11-.438-.128l1.35-4.593a.228.228 0 01.284-.155zM73.72 47.925a.228.228 0 01-.19.26l-6.406.99a.228.228 0 11-.07-.451l6.406-.99a.228.228 0 01.26.191z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M68.426 42.862c.123.027.201.149.174.272l-1.288 5.864a.228.228 0 11-.445-.098l1.288-5.864a.228.228 0 01.271-.174z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#DDEAF9"
                d="M62.382 186.22c34.453 0 62.383-2.227 62.383-4.973s-27.93-4.973-62.383-4.973S0 178.501 0 181.247s27.93 4.973 62.382 4.973z"
            ></path>
            <path
                fill="#B2D2FF"
                d="M103.938 159.859V92.057h-.011c.231-5.837-3.854-11.725-12.27-16.195-16.317-8.663-42.687-8.706-58.9-.097-7.476 3.967-11.477 9.085-12.031 14.294v.046a11.83 11.83 0 000 2.441v67.409c0 11.688 10.437 21.171 23.335 21.202l36.434.087c12.945.03 23.458-9.47 23.458-21.201l-.015-.184z"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M103.828 108.858a.28.28 0 01.279.279v51.199c0 11.91-10.665 21.511-23.738 21.479l-25.542-.06a.28.28 0 01.001-.558l25.542.059c12.818.032 23.179-9.37 23.179-20.92v-51.199a.28.28 0 01.279-.279z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#B2D2FF"
                d="M90.281 66.67H32.026a5.022 5.022 0 000 10.044h58.255a5.022 5.022 0 000-10.043z"
            ></path>
            <path
                fill="#F9A56E"
                d="M83.339 169.028H42.192v9.361h41.147v-9.361z"
            ></path>
            <path
                fill="#D98753"
                d="M81.819 169.028H66.553v9.361h15.266v-9.361z"
            ></path>
            <path
                fill="#E68F58"
                d="M65.127 169.028h-6.396v9.361h6.396v-9.361z"
            ></path>
            <path
                fill="#F9B589"
                d="M49.272 169.028h-3.511v9.361h3.511v-9.361z"
            ></path>
            <path
                fill="#A36136"
                d="M83.339 176.999H42.192v1.39h41.147v-1.39z"
            ></path>
            <path
                fill="#F9A56E"
                d="M83.339 159.918H42.192v9.362h41.147v-9.362z"
            ></path>
            <path
                fill="#D98753"
                d="M81.819 159.918H66.553v9.362h15.266v-9.362z"
            ></path>
            <path
                fill="#E68F58"
                d="M65.127 159.918h-6.396v9.362h6.396v-9.362z"
            ></path>
            <path
                fill="#F9B589"
                d="M49.272 159.918h-3.511v9.362h3.511v-9.362z"
            ></path>
            <path
                fill="#A36136"
                d="M83.339 167.89H42.192v1.39h41.147v-1.39z"
            ></path>
            <path
                fill="#F9A56E"
                d="M80.594 150.81H39.447v9.361h41.147v-9.361z"
            ></path>
            <path
                fill="#D98753"
                d="M79.074 150.81H63.807v9.361h15.267v-9.361z"
            ></path>
            <path
                fill="#E68F58"
                d="M62.382 150.81h-6.396v9.361h6.396v-9.361z"
            ></path>
            <path
                fill="#F9B589"
                d="M46.527 150.81h-3.511v9.361h3.511v-9.361z"
            ></path>
            <path
                fill="#A36136"
                d="M80.594 158.781H39.447v1.391h41.147v-1.391z"
            ></path>
            <path
                fill="#F9A56E"
                d="M82.397 141.4H41.25v9.362h41.147V141.4z"
            ></path>
            <path
                fill="#D98753"
                d="M80.877 141.4H65.611v9.362h15.266V141.4z"
            ></path>
            <path
                fill="#E68F58"
                d="M64.186 141.4h-6.397v9.362h6.397V141.4z"
            ></path>
            <path
                fill="#F9B589"
                d="M48.33 141.4h-3.51v9.362h3.51V141.4z"
            ></path>
            <path
                fill="#A36136"
                d="M82.397 149.372H41.25v1.39h41.147v-1.39z"
            ></path>
            <path
                fill="#F9A56E"
                d="M82.399 132.291H41.252v9.361H82.4v-9.361z"
            ></path>
            <path
                fill="#D98753"
                d="M80.877 132.291H65.611v9.361h15.266v-9.361z"
            ></path>
            <path
                fill="#E68F58"
                d="M64.186 132.291h-6.397v9.361h6.397v-9.361z"
            ></path>
            <path
                fill="#F9B589"
                d="M48.33 132.291h-3.51v9.361h3.51v-9.361z"
            ></path>
            <path
                fill="#A36136"
                d="M82.397 140.262H41.25v1.39h41.147v-1.39z"
            ></path>
            <path
                fill="#F9A56E"
                d="M84.651 123.181H43.504v9.362h41.147v-9.362z"
            ></path>
            <path
                fill="#D98753"
                d="M83.131 123.181H67.865v9.362H83.13v-9.362z"
            ></path>
            <path
                fill="#E68F58"
                d="M66.44 123.181h-6.397v9.362h6.397v-9.362z"
            ></path>
            <path
                fill="#F9B589"
                d="M50.584 123.181h-3.51v9.362h3.51v-9.362z"
            ></path>
            <path
                fill="#A36136"
                d="M84.651 131.153H43.504v1.39h41.147v-1.39z"
            ></path>
            <path
                fill="#F5F8FF"
                d="M42.256 93.04c.445-5.335-3.52-10.02-8.856-10.465-5.335-.444-10.02 3.52-10.465 8.856-.444 5.335 3.521 10.021 8.856 10.465 5.336.444 10.021-3.52 10.465-8.856zM48.666 84.99a4.162 4.162 0 10-2.657-7.888 4.162 4.162 0 002.657 7.888z"
                opacity="0.71"
            ></path>
            <path
                fill="#95C3DD"
                fillRule="evenodd"
                d="M55.62 34.026a1.255 1.255 0 011.483.973c2.167 10.434 3.88 23.652 4.817 31.705a1.255 1.255 0 01-2.493.29c-.936-8.042-2.639-21.166-4.781-31.484a1.255 1.255 0 01.973-1.484z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#8BB6CE"
                fillRule="evenodd"
                d="M60.745 66.684a1.255 1.255 0 011.392 1.102c1.241 10.656 1.506 17.89 1.457 22.478-.025 2.295-.128 3.93-.227 5a26.19 26.19 0 01-.18 1.503 8.334 8.334 0 01-.02.107l-.001.008v.005l-1.227-.233 1.226.234a1.255 1.255 0 01-2.466-.47m0 0l.002-.007.008-.052c.008-.05.021-.13.037-.243.031-.227.075-.583.121-1.082.093-.998.193-2.564.217-4.797.048-4.465-.209-11.592-1.44-22.16a1.255 1.255 0 011.1-1.393"
                clipRule="evenodd"
            ></path>
            <path
                fill="#93C1DB"
                d="M59.18 57.282s-12.157 5.374-32.299-17.28c0 0 29.99-5.816 32.298 17.28z"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M32.606 41.843a.229.229 0 01.307-.103L54.46 52.52a.229.229 0 11-.205.409l-21.547-10.78a.229.229 0 01-.102-.306z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M37.287 41.083a.229.229 0 01.314.08l2.487 4.2-4.117 1.45a.229.229 0 01-.152-.433l3.591-1.264-2.203-3.72a.229.229 0 01.08-.313zM45.004 44.84a.229.229 0 01.316.068l3.105 4.82-5.458 1.07a.229.229 0 11-.088-.45l4.796-.94-2.74-4.252a.229.229 0 01.069-.316zM57.787 53.48a.28.28 0 01.317.237l1.285 8.792a.28.28 0 11-.553.08l-1.285-8.791a.28.28 0 01.236-.317z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#FBC477"
                d="M73.546 19.681C73.666 9.053 65.148.34 54.52.218 43.891.098 35.178 8.616 35.057 19.245c-.12 10.628 8.398 19.342 19.026 19.463 10.629.12 19.343-8.398 19.463-19.027z"
            ></path>
            <path
                fill="#F99D1C"
                d="M67.477 19.698c.163-7.148-5.5-13.076-12.648-13.24-7.149-.163-13.076 5.5-13.24 12.648-.163 7.149 5.5 13.077 12.648 13.24 7.149.164 13.076-5.499 13.24-12.648z"
            ></path>
            <path
                fill="#fff"
                d="M54.431 25.803a6.511 6.511 0 01-3.793-1.282l.691-1.298c.996.699 2.148 1.231 3.452 1.102 1.579-.153 2.288-1.111 2.15-2.442-.344-3.457-6.226-2.118-6.64-6.282-.189-1.9.916-3.389 2.747-3.851l-.214-2.156 1.28-.128.211 2.114c1.373.013 2.344.586 3.192 1.32l-.834 1.14c-.867-.69-1.574-1.068-2.726-.958-1.301.13-2.055 1.024-1.931 2.263.305 3.096 6.18 1.65 6.637 6.239.202 2.028-.94 3.564-2.94 4.044l.213 2.156-1.28.128-.215-2.109z"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M42.264 10.033c-.24 1.023-.666 2.2-1.413 2.9a.625.625 0 00.175 1.016 8.24 8.24 0 012.594 1.866.626.626 0 001.084-.273 6.867 6.867 0 011.294-2.726.624.624 0 00-.24-.953 5.44 5.44 0 01-2.351-2.039.628.628 0 00-1.143.21z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#A36136"
                fillRule="evenodd"
                d="M67.621 16.96a.25.25 0 01.272.227c.573 6.45-2.265 10.343-5.25 12.613a17.15 17.15 0 01-5.952 2.88 6.011 6.011 0 01-.135.03l-.008.002h-.002v.001l-.052-.245.051.245a.25.25 0 01-.102-.49m0 0h.001l.006-.001a15.67 15.67 0 001.855-.585 16.648 16.648 0 004.035-2.235c2.873-2.185 5.61-5.92 5.054-12.17a.25.25 0 01.227-.272M42.607 3.65a.25.25 0 10.326.38"
                clipRule="evenodd"
            ></path>
            <path
                fill="#F9A56E"
                d="M81.364 114.684H40.217v9.361h41.147v-9.361z"
            ></path>
            <path
                fill="#D98753"
                d="M79.844 114.684H64.578v9.361h15.266v-9.361z"
            ></path>
            <path
                fill="#E68F58"
                d="M63.151 114.684h-6.396v9.361h6.396v-9.361z"
            ></path>
            <path
                fill="#F9B589"
                d="M47.296 114.684h-3.511v9.361h3.511v-9.361z"
            ></path>
            <path
                fill="#A36136"
                d="M81.364 122.655H40.217v1.39h41.147v-1.39z"
            ></path>
            <path
                fill="#F9A56E"
                d="M81.364 105.574H40.217v9.362h41.147v-9.362z"
            ></path>
            <path
                fill="#D98753"
                d="M79.844 105.574H64.578v9.362h15.266v-9.362z"
            ></path>
            <path
                fill="#E68F58"
                d="M63.151 105.574h-6.396v9.362h6.396v-9.362z"
            ></path>
            <path
                fill="#F9B589"
                d="M47.296 105.574h-3.511v9.362h3.511v-9.362z"
            ></path>
            <path
                fill="#A36136"
                d="M81.364 113.545H40.217v1.39h41.147v-1.39z"
            ></path>
            <path
                fill="#F9A56E"
                d="M83.618 96.465H42.47v9.361h41.147v-9.361z"
            ></path>
            <path
                fill="#D98753"
                d="M82.098 96.465H66.832v9.361h15.266v-9.361z"
            ></path>
            <path
                fill="#E68F58"
                d="M65.405 96.465H59.01v9.361h6.396v-9.361z"
            ></path>
            <path
                fill="#F9B589"
                d="M49.55 96.465h-3.511v9.361h3.51v-9.361z"
            ></path>
            <path
                fill="#A36136"
                d="M83.618 104.436H42.47v1.39h41.147v-1.39z"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M68.625 75.861a.28.28 0 01.279-.28h17.3a.28.28 0 010 .56h-17.3a.28.28 0 01-.28-.28zM30.017 75.861a.28.28 0 01.28-.28h5.473a.28.28 0 010 .56h-5.474a.28.28 0 01-.279-.28z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E2EDFF"
                fillRule="evenodd"
                d="M81.338 55.016s-13.885-.78-16.871 11.615l11.615-.067s-1.221-6.453 5.912-9.39l-.656-2.158z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M156.518 103.534c2.301-.578 4.566.371 5.612 3.165l2.457 14.363-2.762-.728-3.43-10.416-1.877-6.384z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                d="M161.825 120.334l1.253 2.333a2.24 2.24 0 001.111 1.019l1.887.776c.659.271 1.337-.235 1.314-.982l-1.723-2.842a3.332 3.332 0 00-1.732-1.374l-1.22-.433-.89 1.503z"
            ></path>
            <path
                fill="#5B94EA"
                fillRule="evenodd"
                d="M147.549 86.876c3.485.562 13.771 15.444 14.131 17.003.406 1.753 3.089 11.877 3.089 11.877l-5.129.797s-3.421-8.414-4.109-9.064c-.688-.65-10.216-9.765-10.216-9.765l2.234-10.848z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M121.377 78.987c-2.441.574-4.82-.457-5.882-3.434l-2.374-15.224 2.91.813 3.463 11.064 1.883 6.781z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                d="M116.031 61.142l-1.288-2.485a2.372 2.372 0 00-1.158-1.096l-1.984-.85c-.693-.296-1.417.229-1.405 1.016l1.779 3.028a3.512 3.512 0 001.808 1.487l1.283.478.965-1.578z"
            ></path>
            <path
                fill="#5B94EA"
                fillRule="evenodd"
                d="M130.601 96.738c-3.676-.647-14.319-16.544-14.674-18.196-.401-1.862-3.079-12.607-3.079-12.607l5.435-.763s3.483 8.95 4.201 9.647c.717.697 10.649 10.481 10.649 10.481l-2.532 11.438z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#572A22"
                fillRule="evenodd"
                d="M133.363 78.39s-1.59-2.012.754-3.86c2.344-1.847 2.328 2.92 2.254 2.976-.075.056-3.008.883-3.008.883z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#572A22"
                fillRule="evenodd"
                d="M139.969 79.175s-.478 2.416-2.323 2.332c-1.845-.084-1.449-5.375-1.449-5.375l3.772 3.043z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M134.112 79.952l-.058 4.855a1.581 1.581 0 00.916 1.413 2.028 2.028 0 001.845-.029l.538-.29c.78-.416 1.221-1.193 1.137-1.993l-.61-5.778-3.765 1.822h-.003z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#633C1D"
                fillRule="evenodd"
                d="M133.917 81.64l.157 1.502 1.689-1.281-1.846-.22z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M138.888 77.29a1.406 1.406 0 00-.989-1.722l-2.594-.69c-.743-.198-1.394.29-1.704 1.003-.366.842-.709 2.699-.796 4.075-.051.778.349 1.613 1.094 1.812.793.21 3.375.08 3.981-1.294.457-1.047.715-2.076 1.005-3.183h.003z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#572A22"
                fillRule="evenodd"
                d="M140.313 79.676s.705-2.922-.335-4.154c-1.398-1.655-3.398-2.685-5.861-.995 0 0-1.334.64 2.664 1.578.508.119-.476 2.51.153 2.79.851.376 1.951.66 3.376.78h.003z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M138.931 80.5c-.36.526-1.002.707-1.431.405-.429-.302-.486-.972-.124-1.496.362-.525 1.003-.709 1.431-.406.429.302.486.978.124 1.496z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#DDEAF9"
                d="M149.723 186.356c16.706 0 30.249-1.49 30.249-3.327s-13.543-3.326-30.249-3.326-30.249 1.489-30.249 3.326 13.543 3.327 30.249 3.327z"
            ></path>
            <path
                fill="#E3633D"
                fillRule="evenodd"
                d="M136.575 179.713s3.348-21.449-.719-29.936l2.46-29.098-13.513.935 7.997 58.312 3.775-.213z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M132.658 178.87l-1.955 1.961c-.529.485-3.411 1.426-3.411 1.426-.292.601.267.916.741.998l8.487-.178s1.962-.409.25-4.203c-1.552.686-3.718 1.175-4.112-.004zM123.907 126.071l2.873 24.733 3.936 24.696h9.883s-.893-4.295-.774-5.657c.119-1.363.571-14.29-2.4-20.237l.41-24.221-13.928.686z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#252525"
                fillRule="evenodd"
                d="M137.495 149.612l.411-24.221-4.694.259-.305 17.735a39.38 39.38 0 001.159 9.891c1.558 6.453 1.233 15.448 1.136 16.573-.119 1.362.773 5.658.773 5.658h4.689s-.892-4.296-.773-5.658c.119-1.363.573-14.29-2.396-20.237z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E3633D"
                fillRule="evenodd"
                d="M167.556 176.279s-4.383-21.289-11.254-28.076l-7.935-28.249-12.538 4.967 28.334 52.642 3.393-1.284z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M164.166 176.947l-.473 2.73c-.121.693-1.848 3.132-1.848 3.132.153.644.801.562 1.234.344l6.734-5.152s1.325-1.48-2.469-3.44c-.815 1.451-2.11 3.076-3.178 2.386zM134.857 125.91l13.157 26.007 12.473 22.161 9.421-2.99s-2.368-3.791-2.736-5.115c-.368-1.324-4.503-13.681-9.433-18.404l-8.492-25.378-14.39 3.719z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M144.133 106.319l-18.463 1.407c-3.003 1.101-1.804 18.413-1.804 18.413l12.137.563c5.646-.118 12.309 4.304 13.011-5.092.807-10.741-4.881-15.291-4.881-15.291z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#252525"
                fillRule="evenodd"
                d="M149.505 122.843s-12.567 1.202-16.552-4.177l16.421-1.41s.38 4.213.131 5.587z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5B94EA"
                fillRule="evenodd"
                d="M126.133 85.29l6.409-1.153 5.544-.554 8.995 2.715c2.415 1.102 1.75 3.357.915 5.885l.946 8.016c2.03 6.037 2.153 10.545 2.034 16.828-9.895 4.036-24.991 3.585-28.296-.915.139-12.569-.106-17.178 3.453-30.821z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                d="M132.542 84.137s-2.046 5.568 1.734 5.689c3.779.12 5.664-5.657 5.664-5.657l-2.794-1.136-4.604 1.104z"
            ></path>
            <path
                fill="#8795CC"
                fillRule="evenodd"
                d="M147.397 95.915c.126.05.188.192.138.319l-.367.922 2.615 4.005a.246.246 0 01-.072.34.245.245 0 01-.34-.071l-2.684-4.112a.246.246 0 01-.022-.225l.413-1.041a.246.246 0 01.319-.138zM125.122 90.61a.246.246 0 01.149.315l-.232-.083-.231-.083a.246.246 0 01.314-.148zm-.083.232l-.231-.083-.028.078-.073.206-.226.646c-.084.242-.17.493-.238.704a5.728 5.728 0 00-.142.477.246.246 0 00.482.097c.011-.057.057-.208.127-.421.067-.207.152-.456.235-.696l.225-.643.073-.206.028-.076-.232-.083z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5B94EA"
                fillRule="evenodd"
                d="M81.994 57.173s.87-.956 2.12 1.497c1.25 2.454 2.37 14.69 13.942 10.11l3.872-1.641-3.616-7.3s-7.391 7.613-11.635-2.39c-1.78-4.194-4.554-2.715-5.415-2.578l.732 2.302zM109.097 52.417l-3.192 1.326.867 2.09 2.157-.896c.733-.306 1.625.169 1.993 1.057l1.508 3.632c.369.888.074 1.855-.658 2.159l-2.158.895.867 2.09 3.192-1.327c1.113-.457 1.56-1.927 1-3.276l-2.55-6.143c-.556-1.349-1.915-2.067-3.026-1.607z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5B94EA"
                fillRule="evenodd"
                d="M105.877 71.044l-5.464 1.207a6.57 6.57 0 01-7.837-5.002l-.235-12.903c0-.035 1.177-.986 1.212-.993l8.032-1.114c-.241.374 4.183-.61 4.19-.572l5.109 11.534a6.564 6.564 0 01-.877 4.96 6.57 6.57 0 01-4.13 2.883z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#4067A3"
                d="M105.751 51.6c.192.924-2.654 2.316-6.354 3.106s-6.854.68-7.046-.245c-.193-.925 2.899-1.984 6.591-2.775 3.693-.79 6.618-1.014 6.809-.087z"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M133.433 79.164l1.335.327s-.216 1.076-.915.818c-.699-.258-.42-1.145-.42-1.145z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default SaveMoney;
