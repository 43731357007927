import React from "react";

function SaveTime() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="206"
            height="187"
            fill="none"
            viewBox="0 0 206 187"
        >
            <path
                fill="#B2D2FF"
                d="M55.045 62.532c-7.655 6.272-9.346 13.271-9.449 17.946a194.214 194.214 0 00-6.413-15.673c14.814-11.18 6.882-22.366 6.882-22.366-7.3 6.211-8.995 13.002-9.11 17.674a229.709 229.709 0 00-9.125-16.76c13.346-14.167 3.101-23.94 3.101-23.94-6.535 8.182-6.369 15.768-5.329 20.3-3.059-4.897-5.474-8.355-6.611-9.927C13.026 14.656 0 14.46 0 14.46c3.802 14.714 12.574 16.324 16.817 16.184.89 1.219 3.511 4.891 6.938 10.383-11.921-5.866-20.968.24-20.968.24 11.77 10.093 19.978 6.042 23.253 3.5a227.988 227.988 0 018.875 16.324c.313.642.626 1.3.95 1.968-10.929-9.072-21.598-4.536-21.598-4.536C24.68 71.67 34.163 67.775 37.225 65.96c2.295 5.005 4.605 10.57 6.686 16.53-10.57-8.644-20.949-4.462-20.949-4.462 9.568 12.547 18.59 9.997 22.202 8.186 4.187 12.946 7.123 27.477 6.466 41.902a1.129 1.129 0 001.077 1.211 1.117 1.117 0 00.825-.305 1.138 1.138 0 00.357-.804c.677-14.859-2.37-29.792-6.688-43.047 15.76-11.033 7.844-22.638 7.844-22.638z"
            ></path>
            <path
                fill="#B2D2FF"
                d="M93.272 58.963c-8.894 4.458-12.088 10.926-13.215 15.47a192.335 192.335 0 00-2.869-16.708c17.004-7.692 11.662-20.35 11.662-20.35-8.535 4.476-11.687 10.74-12.824 15.277 0-.022-.01-.047-.017-.069a228.97 228.97 0 00-5.274-18.291c16.216-10.93 8.295-22.709 8.295-22.709-8.213 6.575-9.714 14.015-9.685 18.669-1.932-5.45-3.554-9.356-4.32-11.138C62.482 3.035 49.729 0 49.729 0c.509 15.203 8.774 18.687 12.975 19.474.606 1.384 2.377 5.543 4.539 11.653C56.816 22.8 46.59 26.794 46.59 26.794c9.35 12.424 18.302 10.254 22.076 8.487a225.817 225.817 0 015.142 17.878c.168.697.337 1.409.504 2.129-8.75-11.24-18.986-8.622-18.986-8.622 7.346 15.113 16.28 12.858 19.68 11.751a189.754 189.754 0 012.944 17.604c-8.49-10.748-19.603-8.923-19.603-8.923 6.65 14.342 16.072 13.818 20.017 12.836 1.276 13.558.976 28.392-2.834 42.34a1.125 1.125 0 00.113.861 1.143 1.143 0 002.091-.27c3.922-14.365 4.204-29.617 2.868-43.505 17.899-7.337 12.67-20.397 12.67-20.397z"
            ></path>
            <path
                fill="#7A3511"
                fillRule="evenodd"
                d="M31.46 94.748s1.307.94 1.368 2.658c.061 1.718 2.286 1.97 1.571 3.587-.715 1.616-1.571.665-1.034 2.248.538 1.583.629 1.497-1.378 2.248-2.006.752-4.33.887-5.47-.664-1.14-1.551.96-1.481-1.076-.276-2.037 1.204-5.637 1.638-6.202.465-.564-1.172-2.85.539-3.131-1.552-.282-2.091.942-1.136.97-3.53.026-2.392 1.316-1.886 2.042-2.772.726-.887 1.087-.477 2.58-1.553s2.024-1.624 2.024-1.624l3.258 1.403 4.477-.638z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#7A3511"
                fillRule="evenodd"
                d="M26.095 92.92c-.834-.323-1.728.326-2.295.915-1.15 1.197-1.792 1.837-2.85 3.075-1.092 1.247 12.119 2.283 11.983 1.75-.276-1.1-.321-2.41-.9-3.424-.853-2.04-4.57-3.78-5.938-2.316z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M28.916 99.6l-.786 5.001a1.671 1.671 0 01-.364.806 1.73 1.73 0 01-2.435.228l-.45-.374a2.163 2.163 0 01-.681-1.004 2.122 2.122 0 01-.027-1.205l1.567-5.856 3.178 2.404h-.002z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#BA5132"
                fillRule="evenodd"
                d="M28.918 101.246l-.532 1.573-1.878-1.606 2.41.033z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M24.13 96.275a1.39 1.39 0 01.216-1.086c.22-.32.564-.545.957-.624l2.838-.555c.815-.154 1.487.38 1.778 1.13.342.887.597 2.814.605 4.234 0 .802-.482 1.634-1.286 1.795-.87.17-3.647-.118-4.214-1.566-.434-1.102-.643-2.173-.887-3.328h-.007z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M23.532 99.748c.395.595 1.15.831 1.685.532.535-.3.647-1.027.251-1.62-.395-.593-1.15-.831-1.685-.53-.535.302-.647 1.024-.252 1.618z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M29.713 98.803l-.795.444s.133 1.447.506 1.112c.373-.334.29-1.556.29-1.556z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#7A3511"
                fillRule="evenodd"
                d="M29.721 94.13s-1.156 4.641-4.929 3.758c-3.773-.884.775-3.905.775-3.905l4.154.148z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#59270D"
                fillRule="evenodd"
                d="M26.517 93.227c.024-.038.092-.057.152-.042l-.044.07-.043.07c-.06-.016-.09-.06-.066-.098zm.108.028l-.043.07.022.005.064.017a52.466 52.466 0 01.94.252c.261.073.526.15.735.216.104.034.192.064.259.09a1.093 1.093 0 01.103.047c.039.034.112.041.164.017.053-.024.065-.071.027-.105a.34.34 0 00-.075-.045 1.334 1.334 0 00-.098-.042 5.097 5.097 0 00-.275-.096 21.603 21.603 0 00-.746-.22 52.86 52.86 0 00-1.01-.27l-.023-.006-.044.07zM24.82 96.613l-.006.002-.02.008a6.12 6.12 0 00-.349.146c-.226.103-.527.25-.826.434a3.7 3.7 0 00-.808.639c-.214.239-.343.49-.334.75.004.126.01.246.015.36.007.145.014.279.016.398.003.214-.01.404-.067.566a.747.747 0 01-.348.407c-.176.103-.413.172-.724.221-.571.091-1.01.368-1.22.69-.207.317-.194.679.136.979.409.373.534.959.395 1.429-.07.237-.211.454-.43.6-.221.148-.511.216-.858.169-.073-.01-.123-.069-.112-.131.012-.062.08-.105.154-.095.28.038.492-.018.65-.123a.855.855 0 00.335-.476c.12-.406.006-.909-.331-1.217-.417-.38-.431-.852-.175-1.246.254-.389.767-.702 1.407-.804.295-.047.49-.108.621-.185a.512.512 0 00.243-.285c.045-.126.058-.286.055-.496-.002-.118-.008-.245-.015-.386-.006-.113-.012-.235-.016-.369-.011-.33.153-.632.39-.895.236-.264.552-.495.863-.686a7.796 7.796 0 011.223-.603l.021-.008.006-.003h.002l.054.105-.054-.105c.069-.026.148.001.177.06.03.057-.001.125-.07.15zM31.472 98.854c.032-.055.092-.068.136-.028l-.057.1.057-.1.002.002.002.002a.49.49 0 01.038.04c.024.026.057.065.095.118.075.104.168.262.245.475.154.43.232 1.072-.033 1.939-.218.71-.58 1.063-.851 1.328-.063.062-.122.119-.172.174-.135.148-.208.276-.214.451-.007.186.06.454.276.881.03.057.016.133-.03.17-.044.037-.105.02-.134-.037-.22-.436-.317-.759-.307-1.025.01-.277.133-.463.282-.626.06-.066.124-.13.192-.196.27-.265.58-.57.776-1.209.248-.808.17-1.38.037-1.748a1.675 1.675 0 00-.211-.411 1.127 1.127 0 00-.1-.122l-.006-.005c-.044-.04-.054-.117-.023-.173z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#DDEAF9"
                d="M104.024 178.327c39.262 0 71.09-3.416 71.09-7.631 0-4.214-31.828-7.631-71.09-7.631s-71.09 3.417-71.09 7.631c0 4.215 31.828 7.631 71.09 7.631z"
            ></path>
            <path
                fill="#fff"
                d="M146.427 47.393H57.605c-9.633 0-17.441 7.877-17.441 17.593v57.191c0 9.716 7.808 17.593 17.44 17.593h88.823c9.633 0 17.441-7.877 17.441-17.593v-57.19c0-9.717-7.808-17.594-17.441-17.594z"
            ></path>
            <path
                fill="#5B94EA"
                d="M146.427 47.393H57.605c-9.594 0-17.441 7.882-17.441 17.513v9.799h123.704v-9.799c0-9.631-7.849-17.513-17.441-17.513z"
            ></path>
            <path
                fill="#000"
                d="M72.295 43.198c0-1.676-1.259-3.034-2.812-3.034-1.552 0-2.811 1.358-2.811 3.034v11.604c0 1.675 1.259 3.034 2.811 3.034 1.553 0 2.812-1.359 2.812-3.034V43.198zM92.377 43.198c0-1.676-1.259-3.034-2.812-3.034-1.552 0-2.811 1.358-2.811 3.034v11.604c0 1.675 1.259 3.034 2.811 3.034 1.553 0 2.812-1.359 2.812-3.034V43.198zM113.262 43.198c0-1.676-1.439-3.034-3.213-3.034-1.775 0-3.213 1.358-3.213 3.034v11.604c0 1.675 1.438 3.034 3.213 3.034 1.774 0 3.213-1.359 3.213-3.034V43.198zM130.532 57.836a2.721 2.721 0 01-1.985-.892 3.169 3.169 0 01-.826-2.143V43.197a3.167 3.167 0 01.826-2.142 2.72 2.72 0 011.985-.891 2.72 2.72 0 011.986.89c.526.569.823 1.34.826 2.143v11.609a3.172 3.172 0 01-.828 2.14 2.718 2.718 0 01-1.984.89z"
            ></path>
            <path
                fill="#E0E1EB"
                d="M68.278 81.934H54.623v12.05h13.655v-12.05zM68.278 98.803H54.623v12.049h13.655v-12.05zM68.278 117.278H54.623v11.246h13.655v-11.246zM88.36 81.934H75.508v12.05H88.36v-12.05zM88.36 98.803H75.508v12.049H88.36v-12.05zM88.36 117.278H75.508v11.246H88.36v-11.246zM109.246 81.934H95.59v12.05h13.656v-12.05zM109.246 98.803H95.59v12.049h13.656v-12.05zM109.246 117.278H95.59v11.246h13.656v-11.246zM130.131 81.934h-13.656v12.05h13.656v-12.05zM130.131 98.803h-13.656v12.049h13.656v-12.05zM130.131 117.278h-13.656v11.246h13.656v-11.246zM150.213 81.934h-13.656v12.05h13.656v-12.05zM150.213 98.803h-13.656v12.049h13.656v-12.05zM150.213 117.278h-13.656v11.246h13.656v-11.246z"
            ></path>
            <path
                fill="#F99D1C"
                d="M81.934 114.065c5.767 0 10.443-4.675 10.443-10.442 0-5.768-4.676-10.443-10.443-10.443-5.767 0-10.442 4.675-10.442 10.443 0 5.767 4.675 10.442 10.442 10.442z"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M86.6 99.652c.154.087.2.27.104.409L81.54 107.5a.339.339 0 01-.27.139.341.341 0 01-.278-.127l-2.212-2.859c-.104-.134-.067-.319.081-.413a.351.351 0 01.457.073l1.929 2.492 4.9-7.06a.35.35 0 01.453-.093z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#DDEAF9"
                d="M47.795 186.36c23.734 0 42.975-1.259 42.975-2.811 0-1.553-19.24-2.812-42.975-2.812-23.735 0-42.975 1.259-42.975 2.812 0 1.552 19.24 2.811 42.975 2.811z"
            ></path>
            <path
                fill="#5B94EA"
                d="M16.066 181.54h31.327v-31.327H16.066v31.327z"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M19.414 154.229l24.454-.05.312-6.444-16.295-8.966-9.565-2.279-2.978-1.54c-4.53 5.594-2.706 19.083 4.072 19.279z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M33 150.749l23.13 1.873 4.919-7.202-7.29-4.294-21.863-7.782-3.95 4.372c-4.572 5.07-1.784 12.25 5.055 13.033z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M49.803 146.623s4.023.416 7.934 12.66c2.146 6.711 13.337 22.257 13.337 22.257l2.024-2.079-10.146-34.008a8.698 8.698 0 00-2.117-3.283 8.386 8.386 0 00-3.311-1.98l-6.821-2.027-.9 8.46z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#4A2A1A"
                fillRule="evenodd"
                d="M73.48 178.327l2.932 1.946c.608.49 3.807 1.306 3.807 1.306.355.635-.23 1.021-.742 1.142l-9.25.426s-2.162-.302-.545-4.564c1.73.634 3.447 1.06 3.799-.256z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M37.754 147.523s4.116.292 8.472 12.514c2.387 6.697 12.437 21.503 12.437 21.503l2.386-1.452-9.916-34.159a8.749 8.749 0 00-2.259-3.243 8.561 8.561 0 00-3.437-1.892l-7.017-1.827-.666 8.556z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#4A2A1A"
                fillRule="evenodd"
                d="M60.821 178.327l2.825 2.151c.582.533 3.742 1.553 3.742 1.553.317.665-.292 1.022-.813 1.116l-9.302-.124s-2.15-.436-.278-4.663c1.698.741 3.395 1.277 3.826-.033z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#252525"
                fillRule="evenodd"
                d="M61.852 175.897l-9.347.824s-1.036-4.513-2.898-7.001c-3.73-4.985-7.284-14.75-7.48-14.928-.197-.178-22.664.255-23.793.1-2.939-.401-9.879-4.726-3.98-19.942l27.782 7.311s6.613.945 8.036 3.284c.92 1.507 1.84 2.946 3.066 5.199 6.474 11.932 8.614 25.153 8.614 25.153z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#252525"
                fillRule="evenodd"
                d="M73.901 175.449l-9.381.468s-8.812-18.07-10.758-21.19c-1.946-3.119-35.287-4.829-35.287-4.829l2.165-14.948 19.475.795s10.907 3.123 11.89 3.427c.983.305 7.377-.99 11.27 6.423 3.894 7.412 10.626 29.854 10.626 29.854z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M43.377 124.035l-5.235-14.156c-1.206-3.422-4.707-4.881-7.358-3.053-1.046.718-1.374 2.31-.74 3.578l7.91 15.71 5.423-2.079z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#D0E0FF"
                fillRule="evenodd"
                d="M34.382 105.988L29.59 104.2l-4.131-.577-2.034.034-7.782 2.521a5.676 5.676 0 00-2.971 6.978l1.694 8.876c-2.156 6.618-3.295 8.254-3.098 15.118 10.05 1.976 27.27.608 28.093-.291-.277-13.739-1.65-23.875-4.979-30.871z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                d="M29.881 104.752s2.324 5.93-1.861 6.097c-4.186.167-6.331-5.988-6.331-5.988l3.082-1.238 5.11 1.129z"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M12.53 127.721l6.14-13.494c1.532-3.234.054-6.363-3.107-6.583-1.245-.086-2.586.891-3.01 2.194L7.23 126.063l5.3 1.658z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M7.418 125.311c-.652 2.743.366 5.383 3.41 6.508l15.68 2.328-.772-3.237-11.35-3.64-6.968-1.959zM41.585 122.098c1.9 2.025 2.489 4.78.783 7.235l-4.088 5.617-2.936-1.536 3.508-6.976 2.733-4.34z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                d="M25.819 130.934l2.625 1.38c.524.276.93.725 1.142 1.266l.86 2.193a1.139 1.139 0 01-.133 1.079c-.11.156-.258.284-.43.372a1.23 1.23 0 01-.558.136l-3.198-1.914a3.665 3.665 0 01-1.545-1.984l-.484-1.42 1.72-1.108zM37.337 132.541l-2.545.747c-.509.151-.954.505-1.255.999l-1.216 2c-.098.162-.16.348-.182.543-.021.195-.001.394.059.578.06.185.157.35.285.481.128.132.28.226.447.274l3.15-1.14c.735-.289 1.356-.866 1.756-1.629l.721-1.316-1.22-1.537z"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M22.492 138.409l24.058.558.04-1.895-22.238-.515a1.784 1.784 0 00-1.298.525 1.93 1.93 0 00-.562 1.327z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M34.54 138.473l21.96.494 6.959-14.779-21.457-.483a.762.762 0 00-.425.116.781.781 0 00-.294.332l-6.742 14.32z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5B94EA"
                fillRule="evenodd"
                d="M35.344 138.473l22.173.494 6.699-14.098a.486.486 0 00-.43-.695l-21.105-.469a.483.483 0 00-.45.278l-6.887 14.49z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M49.059 131.694c-.266.887.392 1.627 1.463 1.649 1.07.023 2.169-.675 2.435-1.562.266-.888-.392-1.627-1.463-1.65-1.07-.022-2.169.675-2.435 1.563z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#7B81B5"
                fillRule="evenodd"
                d="M36.951 140.8a.244.244 0 01.26-.226c5.642.322 9.092 1.68 11.14 2.974 1.023.647 1.693 1.277 2.11 1.749.208.236.353.433.446.573.047.069.081.125.104.164l.026.046.004.008.003.006.002.004.001.001v.001l-.22.107.22-.107.006.011 9.172 20.633a.239.239 0 01-.127.317.248.248 0 01-.324-.124l-9.169-20.626-.002-.004-.02-.034a2.463 2.463 0 00-.087-.139 4.992 4.992 0 00-.408-.522c-.387-.439-1.022-1.038-2.005-1.659-1.964-1.242-5.329-2.581-10.9-2.899a.242.242 0 01-.232-.254z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#D0E0FF"
                fillRule="evenodd"
                d="M20.025 111.945l-3.875 10.153-8.117-3.23c1.608-1.722 3.41-10.085 7.903-12.351 4.944-2.491 4.09 5.428 4.09 5.428zM33.913 106s3.417.299 5.09 6c1.675 5.701 3.243 6.887 3.243 6.887l-6.54 2.375L31 109.421 33.913 106z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#7279B5"
                fillRule="evenodd"
                d="M34.965 110.296v.033c.001.026.002.068.007.126.009.115.029.293.074.531.091.477.285 1.199.697 2.158.427.994.929 2.765 1.32 4.27a105.888 105.888 0 01.633 2.558l.038.165.01.043.003.015-.205.059-.206.058-.003-.014-.01-.042-.038-.163a105.463 105.463 0 00-.629-2.546c-.393-1.511-.886-3.244-1.296-4.198-.425-.99-.63-1.747-.729-2.263a5.295 5.295 0 01-.082-.591 2.84 2.84 0 01-.008-.156v-.043V110.277l.212.009-.212-.01c.005-.131.104-.233.221-.227.117.006.208.116.203.246v.001zm2.577 9.958l-.206.058c.03.128.145.204.258.172.114-.033.182-.162.153-.289l-.205.059zM20.639 109.246c.139.003.25.113.246.246l-.026 1.091c0 .03-.007.059-.019.087l-3.485 8.066a.257.257 0 01-.33.131.238.238 0 01-.137-.315l3.468-8.024.024-1.047a.247.247 0 01.259-.235z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#2A496B"
                fillRule="evenodd"
                d="M163.66 122.099c.127.012.22.124.207.25-.92 9.235-5.337 13.605-9.549 15.651-2.1 1.02-4.139 1.459-5.653 1.643-.757.092-1.384.12-1.823.126a10.884 10.884 0 01-.646-.01l-.036-.002H66.099a.23.23 0 01-.23-.229.23.23 0 01.23-.228H146.181l.008.001.031.002.127.005c.112.003.277.006.489.004a16.652 16.652 0 001.773-.123c1.478-.18 3.464-.608 5.506-1.599 4.068-1.976 8.389-6.207 9.293-15.286a.23.23 0 01.252-.205z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                d="M155.853 103.885l-.457-.667a2.067 2.067 0 01-.344-1.457l.298-2.062c.024-.165.09-.323.191-.46.102-.135.238-.246.394-.322a1.143 1.143 0 011.011.007l1.69 2.81c.381.666.503 1.438.345 2.18l-.254 1.304a3.264 3.264 0 01-1.632-.288 3.063 3.063 0 01-1.242-1.045z"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M189.639 179.13l-3.767-.366-.315-3.769 4.819-1.487-.737 5.622zM165.988 178.668l-3.726.463.047-5.212 5.576-1.215-1.897 5.964z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#DDEAF9"
                d="M174.311 184.753c13.309 0 24.098-1.078 24.098-2.409 0-1.331-10.789-2.41-24.098-2.41s-24.098 1.079-24.098 2.41c0 1.331 10.789 2.409 24.098 2.409z"
            ></path>
            <path
                fill="#4A2A1A"
                fillRule="evenodd"
                d="M162.302 177.524l-2.814 2.154c-.553.534-3.571 1.567-3.571 1.567-.3.663.288 1.01.786 1.099l8.906-.198s2.657-.453.832-4.622c-1.618.745-3.716 1.295-4.139 0z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M166.133 125.02l-2.392 25.923-3.086 24.171h9.183s5.795-20.285 4.856-26.393l6.043-24.555c-4.504-4.325-9.061-5.751-14.604.854z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M188.34 105.229l-20.85 1.254c-1.181 3.54-2.015 19.081-2.015 19.081l9.748-.809c6.37-.064 18.325 6.581 19.02-3.114.138-1.923.253-7.574 0-9.127-1.193-7.394-5.903-7.285-5.903-7.285z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M179.131 121.295l2.481 29.521 1.878 25.101h9.873s-.601-4.542-.388-5.989c.213-1.447 1.476-13.323.091-21.485l1.327-27.148h-15.262z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#4A2A1A"
                fillRule="evenodd"
                d="M186.36 179.076s.109 1.696.087 3.109a.907.907 0 00.346.688c.209.186.485.285.767.273l1.596-.121a.912.912 0 00.607-.271l.298-.328c.511-.548.308-1.129.045-1.949a17.15 17.15 0 01-.448-1.814c-1.359-.948-3.298.413-3.298.413z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#0D367F"
                fillRule="evenodd"
                d="M175.918 121.519a.242.242 0 01.258-.224l3.529.208a.24.24 0 01.229.238v6.544a.241.241 0 01-.244.239.241.241 0 01-.243-.239v-6.318l-3.301-.195a.24.24 0 01-.228-.253z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#728F3B"
                fillRule="evenodd"
                d="M193.236 114.868s-13.075-1.693-16.515-1.916l16.868-3.707s-.04 3.973-.353 5.623z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5B94EA"
                fillRule="evenodd"
                d="M175.628 73.53l5.706-1.235 2.166.039 8.267 2.725a6.05 6.05 0 012.977 3.167 6.09 6.09 0 01.095 4.357l.091 7.995c.332 8.284-1.741 16.726-2.053 23.503a2.56 2.56 0 01-.661 1.597 2.54 2.54 0 01-1.523.808c-8.308 1.149-21.788 1.724-25.218-3.172.243-7.51 1.404-17.307 2.715-27.882.858-6.95 4.684-7.157 7.438-11.902z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#4879C2"
                fillRule="evenodd"
                d="M177.524 117.038s16.838-15.004 16.146-30.284l2.329 4.927s-1.037 16.509-.992 23.474c.003-.004-6.288 2.973-17.483 1.883z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E3BDA8"
                fillRule="evenodd"
                d="M172.598 79.57c.112.08.14.24.063.356-.384.585-1.262 2.057-2.048 3.39-.392.664-.759 1.292-1.029 1.754l-.322.552-.088.152-.031.053-.21-.133-.209-.133.031-.054.088-.152.323-.552c.27-.463.638-1.091 1.03-1.757.783-1.328 1.668-2.813 2.061-3.41a.239.239 0 01.341-.066zm-3.792 6.343a.262.262 0 01-.082-.352l.209.133.21.133a.24.24 0 01-.337.086z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5B94EA"
                fillRule="evenodd"
                d="M178.327 73.098c-7.718 1.278-9.169 1.84-11.76 4.886-2.166 2.55-3.325 6.619-5.086 9.736-.442.788 5.725 5.977 6.499 5.418 4.254-3.108 8.935-11.598 8.935-11.598l1.412-8.442z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M178.801 73.048s-2.819 4.796-.081 5.6c2.738.803 7.64-5.219 7.64-5.219l-2.924-1.134-3.433.514-1.202.239z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#3D4A87"
                d="M159.049 100.008c0-8.907 6.014-16.304 13.93-17.794v-.28h2.938c9.318 0 16.869 8.092 16.869 18.074 0 9.981-7.551 18.074-16.869 18.074h-2.933v-.272c-7.917-1.494-13.935-8.893-13.935-17.802z"
            ></path>
            <path
                fill="#4E5EAD"
                d="M173.507 118.082c9.317 0 16.869-8.092 16.869-18.074 0-9.982-7.552-18.074-16.869-18.074-9.316 0-16.868 8.092-16.868 18.074 0 9.982 7.552 18.074 16.868 18.074z"
            ></path>
            <path
                fill="#fff"
                d="M173.507 114.868c7.542 0 13.656-6.653 13.656-14.86s-6.114-14.86-13.656-14.86c-7.541 0-13.655 6.653-13.655 14.86s6.114 14.86 13.655 14.86z"
            ></path>
            <path
                fill="#D1D3ED"
                d="M173.47 85.147c.551.006 1.1.05 1.644.132-6.704.934-11.891 7.16-11.891 14.729 0 7.569 5.187 13.799 11.891 14.728-.544.083-1.093.127-1.644.132-7.522 0-13.618-6.653-13.618-14.86s6.096-14.86 13.618-14.86z"
            ></path>
            <path
                fill="#BABDE0"
                d="M172.124 99.857a.677.677 0 01.488-.25.658.658 0 01.508.204l6.626 7.275a.84.84 0 01.187.559.835.835 0 01-.222.545.66.66 0 01-.995.047l-6.626-7.275a.836.836 0 01-.188-.559.837.837 0 01.222-.546z"
            ></path>
            <path
                fill="#BABDE0"
                d="M172.705 99.606a.847.847 0 01-.567-.213.698.698 0 01-.237-.515v-5.772a.7.7 0 01.236-.515.85.85 0 01.568-.214c.213 0 .417.078.567.214a.699.699 0 01.236.515v5.772a.697.697 0 01-.236.514.847.847 0 01-.567.214z"
            ></path>
            <path
                fill="#D1D3ED"
                d="M180.352 94.348l-.011-.015a.151.151 0 00-.212-.032l-7.715 5.675a.152.152 0 00-.032.212l.011.015c.05.068.145.083.213.033l7.714-5.675a.151.151 0 00.032-.213z"
            ></path>
            <path
                fill="#4E5EAD"
                d="M172.704 101.213c.444 0 .804-.54.804-1.205 0-.666-.36-1.205-.804-1.205-.443 0-.803.54-.803 1.205s.36 1.205.803 1.205z"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M191.084 78.721c-4.451 11.56-3.045 6.432 9.202 19.163.668.697 2.04-2.016 3.026-1.513 1.304.67 2.119 1.362 2.253.977 1.199-3.334-12.653-18.616-12.653-18.616l-1.828-.011z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5B94EA"
                fillRule="evenodd"
                d="M192.404 75.508s2.343 1.093 4.04 2.986c.972 1.074 6.95 10.085 6.781 11.042-1.067 6.204-7.385 5.189-7.385 5.189l-10.283-10.33 6.847-8.887z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M187.163 106.133c4.314-3.041 8.148-8.077 12.602-9.309 2.143-.593 3.604-1.594 4.574-1.103 2.043 1.03 1.642 4.85-.958 6.181-1.58.808-13.794 5.737-13.794 5.737l-2.424-1.506z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#6B719E"
                fillRule="evenodd"
                d="M189.721 82.751a.229.229 0 01.292.132l1.956 5.177a.223.223 0 01-.134.286.229.229 0 01-.292-.131l-1.956-5.177a.223.223 0 01.134-.287z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                d="M190.23 104.491l-3.05-.065a2.62 2.62 0 00-1.676.559l-1.909 1.512a1.191 1.191 0 00-.351.455 1.148 1.148 0 00.095 1.097c.107.162.252.297.425.393l3.841-.128a3.911 3.911 0 002.408-.993l1.167-1.017-.95-1.813z"
            ></path>
            <path
                fill="#734129"
                d="M183.787 70.68c.37.013.657-.216.98-1.16.376-1.21.628-2.46.752-3.728.06-.722.068-1.46-.128-2.152-.195-.692-.619-1.334-1.229-1.622-.722-.343-1.603-.128-2.212.414-.609.542-.969 1.362-1.117 2.2-.147.84-.102 1.705-.018 2.556.093.955.257 1.959.836 2.687.579.728 2.219.85 2.136.805z"
            ></path>
            <path
                fill="#734129"
                fillRule="evenodd"
                d="M177.516 68.278a3.958 3.958 0 01-.755-2.942c.1-.615.02-1.343.619-2.025 2.264-2.573 3.426 3.257 3.354 3.335-.073.078-3.218 1.632-3.218 1.632z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M178.327 69.08s.708 3.148 1.297 5.756a1.909 1.909 0 001.479 1.414 2.47 2.47 0 002.178-.552l.557-.492c.805-.714 1.114-1.756.786-2.676l-2.349-6.661-3.948 3.21z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#633C1D"
                fillRule="evenodd"
                d="M179.131 70.896l.434 1.399 1.172-1.607-1.606.208z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C95836"
                fillRule="evenodd"
                d="M183.945 64.794a1.733 1.733 0 00-.433-1.281 1.831 1.831 0 00-1.235-.61l-3.446-.243c-.989-.07-1.695.686-1.916 1.642-.261 1.123-.248 3.495-.026 5.214.125.97.831 1.906 1.822 1.97 1.057.076 4.292-.692 4.729-2.531.337-1.4.406-2.727.505-4.162v.001z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M177.527 68.278l1.603.305s-.18 1.55-.934 1.268c-.754-.282-.669-1.573-.669-1.573z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#734129"
                d="M183.47 67.417c-.413-.047-.713-.43-.793-.823-.08-.393.009-.797.08-1.185.071-.389.123-.81-.023-1.185s-.519-.789-.345-1.174a.737.737 0 01.518-.365c.574-.13 1.195.18 1.516.656.322.475.384 1.081.291 1.643a6.737 6.737 0 01-.557 1.613l-.283.638a.399.399 0 01-.191.234.25.25 0 01-.213-.052z"
            ></path>
            <path
                fill="#734129"
                d="M182.546 67.095c-.09-.93-.086-1.864.013-2.792a9.618 9.618 0 01-5.094.349 2.419 2.419 0 01-1.125-.464 1.203 1.203 0 01-.364-.532 1.144 1.144 0 01-.026-.632.964.964 0 01.32-.433c.148-.112.327-.184.515-.205.364-.022.728.026 1.071.142-.583.054-1.034-.729-.859-1.331.089-.277.272-.52.519-.69.248-.172.547-.264.855-.261.586 0 1.146.271 1.663.579.518.307 1.328.412 1.895.58.759.226 1.406.442 1.825 1.17.234.404.208.914.174 1.39-.043.586-.37 2.047-.655 3.22-.098.433-.686.364-.727-.09z"
            ></path>
            <path
                fill="#C95836"
                d="M184.609 68.728c.293-.706.059-1.474-.522-1.715-.581-.242-1.29.135-1.583.84-.293.707-.059 1.475.522 1.716.581.241 1.29-.135 1.583-.841z"
            ></path>
            <path
                fill="#4A2A1A"
                fillRule="evenodd"
                d="M183.842 62.657c.071.01.119.06.106.115a1.85 1.85 0 01-.481.863 2.657 2.657 0 01-.931.615c-.064.026-.143.008-.177-.04-.034-.049-.01-.109.054-.135.333-.134.621-.325.842-.557a1.68 1.68 0 00.436-.78c.012-.054.08-.09.151-.081zM177.531 61.979c.02-.103.076-.154.126-.112.193.161.392.292.595.39.052.026.085.135.073.243-.013.108-.065.175-.117.15a3.092 3.092 0 01-.623-.41c-.05-.04-.074-.158-.054-.261z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default SaveTime;
